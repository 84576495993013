import React from 'react'

import Title from 'components/Title'
import { useSiteMetadata } from 'hooks/useSiteMetadata'
import Container from 'components/Container'

import ExternalLink from 'components/ExternalLink'

import StripeFee from 'components/StripeFee'

import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import Footer from "components/Footer"
import Header from "components/Header"

export default () => {

    const { t } = useTranslation()

    const { title: siteTitle } = useSiteMetadata()

    return (
        <>
            <Header />
            <main>
                <Title 
                    dark 
                    title={ t('Contactless payment options') } 
                    subtitle={ t('The easiest way to start accepting online and mobile payments') } 
                />

                <Container>

                    <StripeFee/>

                    <p>
                        { t('Start accepting online and mobile payments in minutes with direct deposit into your bank account.')}
                    </p>
                    
                    <p>
                        <Trans>{{ siteTitle }} uses <ExternalLink href='https://stripe.com'>Stripe</ExternalLink> to process your online payments. Stripe is certified as a PCI Service Provider Level 1 (the most stringent level of certification available in the payments industry).</Trans>
                    </p>

                </Container>

                
            </main>
            <Footer/>
        </>
    )
}