import React from 'react'

import './stripeFee.scss'

import { useTranslation } from 'gatsby-plugin-react-i18next'

export default function StripeFee() {

    const { t } = useTranslation()

    return (
        <center>
            <div className='stripeFeeWrapper'>
                <div className='stripeFeeContainer'>
                    <div className='stripeFeePercent'>
                        { t('stripeFeePercent', '2.9%') }
                    </div>
                    <div className='stripeFeePlus'>+</div>
                    <div className='stripeFeeFixed'>{ t('stripeFeeFixed', '$0.30') }</div>
                </div>
                <div className='stripeDisclaimer'>{ t('stripeFeeOn', 'per successful card charge') }</div>
            </div>
        </center>
    )
}